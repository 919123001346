import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { ResetPw2Schema } from "./validation";
import { useLocation, useHistory } from "react-router-dom";
import { resetPassword } from "../../api/auth";

import { Row, Col, Form } from "react-bootstrap";
import Sallie from "../Sallie";
import SubmitButton from "../StyledComponents/SubmitButton";
import LabelledInput from "../LabelledInput";

const ResetPassword2 = () => {
  const location = useLocation();
  const history = useHistory();
  const { register, handleSubmit, errors, formState } = useForm({
    resolver: yupResolver(ResetPw2Schema),
  });
  const { touched } = formState;
  const url = new URLSearchParams(location.search);
  let token = url.get("token");

  const [sallieMsg, setSallieMsg] = useState(
    `Please enter a new password for your account.`
  );
  const [isLoading, setIsLoading] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  const onSubmit = (data) => {
    setIsLoading(true);
    data.token = token;
    resetPassword(data)
      .then((result) => {
        setSallieMsg("New password is successfully updated!");
        setResetSuccess(true);
      })
      .catch((e) => {
        console.log(e);
        history.push("/oops");
      });
  };

  return (
    <>
      <Sallie text={sallieMsg} />
      {resetSuccess ? (
        <SubmitButton onClick={() => history.push("/signIn")}>
          Go To Login Page
        </SubmitButton>
      ) : (
        <Row className="d-flex justify-content-center">
          <Form
            autoComplete="on"
            method="POST"
            className="mt-2 d-flex flex-column align-items-center"
            style={{ margin: "0 auto" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <LabelledInput
              labelAs="h6"
              name="email"
              type="email"
              label="Email"
              autoComplete="email"
              inputRef={register}
              errors={errors}
              touched={touched}
            />            
            <LabelledInput
              labelAs="h6"
              name="password"
              type="password"
              label="Password"
              inputRef={register}
              errors={errors}
              touched={touched}
            />
            <LabelledInput
              labelAs="h6"
              type="password"
              name="passwordConfirmation"
              label="Confirm Password"
              autoComplete="new-password"
              inputRef={register}
              errors={errors}
              touched={touched}
            />
            <SubmitButton disabled={isLoading} type="submit">
              Reset Password
            </SubmitButton>
          </Form>
        </Row>
      )}
    </>
  );
};

export default ResetPassword2;
